import { Controller } from '@hotwired/stimulus';

// This mirrors the constants defined in app/models/employment_contract/income_section.rb
const INCOME_SECTION_WITHOUT_SSF = ['40_5', '40_6', '40_7', '40_8'];

class EmploymentContractFormController extends Controller {
  static targets = [
    'startDate',
    'probationPeriodDays',
    'probationPassDate',
    'incomeSection',
    'contributeToSsf',
    'hiddenField',
  ];

  updateProbationPassDate() {
    const startDate = this.startDateTarget.datepicker.getDate();
    const probationPeriodDays = parseInt(this.probationPeriodDaysTarget.value);

    if (startDate && probationPeriodDays >= 0) {
      const probationPassDate = new Date(startDate).setDate(
        startDate.getDate() + probationPeriodDays
      );
      this.probationPassDateTarget.datepicker.setDate(probationPassDate);
    } else {
      this.probationPassDateTarget.value = null;
    }
  }

  updateContributeToSsf() {
    if (INCOME_SECTION_WITHOUT_SSF.includes(this.incomeSectionTarget.value)) {
      this.contributeToSsfTarget.checked = false;
      this.contributeToSsfTarget.disabled = true;
    } else {
      this.contributeToSsfTarget.disabled = false;
    }
  }

  update(event) {
    // อ่านค่า hidden_value จาก radio button
    const hiddenValue = event.target.dataset.hiddenValue;
    // ตรวจสอบว่ามี hiddenFieldTarget หรือไม่
    if (this.hasHiddenFieldTarget) {
      // อัปเดตค่าใน hidden field
      this.hiddenFieldTarget.value = hiddenValue;
    }
  }
}

export default EmploymentContractFormController;
